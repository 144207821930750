<h1 class="h1-mobile">
    {{ title }}
  </h1>
  
  <div class="content-nav">
    <h1 class="h1-hide">
      {{ title }}
    </h1>
    <div class="display-switcher">
      <h4
        [ngClass]="{ selected: displaySwitcher === 'experience' }"
        (click)="
          displaySwitcher = 'experience'; jumpToTop(); cleanupTiles(); updateUrl()
        "
      >
        Experience
      </h4>
  
      <h4
        [ngClass]="{ selected: displaySwitcher === 'skills' }"
        (click)="displaySwitcher = 'skills'; jumpToTop(); updateUrl()"
      >
        Skills
      </h4>
  
      <h4
        [ngClass]="{ selected: displaySwitcher === 'portfolio' }"
        (click)="displaySwitcher = 'portfolio'; jumpToTop(); updateUrl()"
      >
        Portfolio
      </h4>
  
      <h4
        [ngClass]="{ selected: displaySwitcher === 'contact' }"
        (click)="displaySwitcher = 'contact'; jumpToTop(); updateUrl()"
      >
        Contact
      </h4>
    </div>
  </div>
  
  <div #appContent class="appContent">
    <!-- Experience section -->
    <div class="justify" [ngClass]="{ hide: displaySwitcher !== 'experience' }">
      <div
        class="parent"
        [@cardAnimation]="tiles.length"
        *ngIf="displaySwitcher === 'experience'"
      >
        <div
          #parent
          *ngFor="let tile of tiles; let i = index"
          class="tile"
          [ngClass]="{ big: tile.big, deepen: tile.toggled, vanish: tile.hide }"
          id="item-{{ i }}"
          (click)="
            tileClick(tile.id, i); scrollToTop(parent); stopPropagation($event)
          "
        >
          <div *ngIf="tile.title || tile.subTitle || tile.text || tile.list">
            <h2 class="h2-mobile-compatible" *ngIf="tile.title">
              {{ tile.title }}
            </h2>
            <h3 *ngIf="tile.subTitle">{{ tile.subTitle }}</h3>
            <p *ngIf="tile.text">{{ tile.text }}</p>
            <div *ngIf="tile.list">
              <ul *ngFor="let item of tile.list">
                <li>{{ item }}</li>
              </ul>
            </div>
            <div class="fadeout"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- Skills section -->
    <div
      class="justify-skills"
      [ngClass]="{ hide: displaySwitcher !== 'skills' }"
    >
      <div
        class="parent-skills"
        [@cardAnimation]="skills.length"
        *ngIf="displaySwitcher === 'skills'"
      >
        <div *ngFor="let skill of skills" class="skill-tile">
          <div *ngIf="skill">
            <h2>{{ skill }}</h2>
          </div>
        </div>
      </div>
    </div>
    <!-- Portfolio section -->
    <div class="justify" [ngClass]="{ hide: displaySwitcher !== 'portfolio' }">
      <div
        class="parent"
        [@cardAnimation]="portfolio.length"
        *ngIf="displaySwitcher === 'portfolio'"
      >
        <div
          *ngFor="let item of portfolio"
          class="tile big portfolio-tile"
          (click)="openLinkUrl(item.link)"
        >
          <div *ngIf="item.title || item.link">
            <div class="image-title-pairing">
              <div class="img-container">
                <img class="image" src="{{ item.source }}" [ngClass]="{ 'hide-img': !item.source }"/>
              </div>
              <div class="keep-left-please">
                <h2 class="h2-mobile-compatible">{{ item.title }}</h2>
                <p class="shrink-the-margins">{{ item.text }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Contact section -->
    <div class="justify" [ngClass]="{ hide: displaySwitcher !== 'contact' }">
      <div
        class="parent"
        [@cardAnimation]="contact.length"
        *ngIf="displaySwitcher === 'contact'"
      >
        <div
          *ngFor="let item of contact"
          class="tile big portfolio-tile contact-tile"
          (click)="openLinkUrl(item.link)"
        >
          <div *ngIf="item.title || item.link">
            <div class="keep-left-please">
              <h2 class="h2-mobile-compatible">{{ item.title }}</h2>
              <p>
                <em>{{ item.link }}</em>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  